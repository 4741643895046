import { type HTMLAttributes, forwardRef } from 'react';

import cn from '@pxui/lib/utils';

const User = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => (
    <div
      className={cn('flex items-center gap-2 py-0 px-2', className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  ),
);

User.displayName = 'User';

export default User;
