import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import {
  AppBarLink,
  AppBarNavigation,
  Dropdown,
  DropdownMenuGroup,
  DropdownMenuItem,
  AppBar,
  AppName,
  SiteNavigation,
  User,
  HelpIcon,
  Button,
  AppsIcon,
} from '@pxui/components/ui';

import { AppbarContext } from '@contexts/AppbarContext';

import { DOCS_URL } from '@constants/common';

const Navbar: FC = () => {
  const { user, logout } = useAuth0();
  const appBarState = useContext(AppbarContext);
  const navigate = useNavigate();

  const userName = user?.name || '';

  const { appName, navigationLinks } = appBarState;

  const handleOnClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <AppBar className="fixed top-0 w-full pl-2 h-[50px]">
      <SiteNavigation>
        <Button
          icon={AppsIcon}
          layout="iconOnly"
          variant="ghost"
          onClick={handleOnClick}
        />
        <AppName appName={appName} prefix="PX" />
        <AppBarNavigation>
          {navigationLinks.map(({ label, path }) => (
            <AppBarLink key={path} linkText={label} linkTo={path} />
          ))}
        </AppBarNavigation>
      </SiteNavigation>
      <User>
        <a href={DOCS_URL} aria-label="PhysicsX Documentation">
          <Button layout="iconOnly" variant="ghost" icon={HelpIcon} />
        </a>
        <Dropdown buttonText={userName} variant="ghost">
          <DropdownMenuGroup key="menu-group-1">
            <DropdownMenuItem
              inset
              key="menu-item-1"
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              Log out
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </Dropdown>
      </User>
    </AppBar>
  );
};

export default Navbar;
