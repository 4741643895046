import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from 'react';

import { cn } from '@pxui/lib/utils';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

const layoutClasses = 'relative flex items-center outline-none';
const spacingClasses = 'px-2 py-1.5';
const sizingClasses = 'w-[260px] h-8';
const disabledClasses =
  'data-[disabled]:pointer-events-none data-[disabled]:opacity-50';

export const DropdownMenuItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Item>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'label-1 text-secondary cursor-pointer hover:state-hover select-none transition-colors',
      inset && 'pl-8',
      layoutClasses,
      spacingClasses,
      sizingClasses,
      disabledClasses,
      className,
    )}
    {...props}
  >
    {children}
  </DropdownMenuPrimitive.Item>
));

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

export default DropdownMenuItem;
