import { Outlet } from 'react-router-dom';

import Auth0ProviderWithHistory from '@auth/Auth0ProviderWithHistory';
import { Toaster } from '@pxui/components/ui/toast';

import FullScreenContainer from '@components/FullScreenContainer/FullScreenContainer';
import { Navbar } from '@components/index';

import { AppbarContextProvider } from '@contexts/AppbarContext';
import { Auth0TokenProvider } from '@contexts/Auth0TokenContext';

import { AuthenticationGuard } from './AuthenticationGuard';

export const Root = () => {
  return (
    <AppbarContextProvider>
      <Auth0ProviderWithHistory>
        <AuthenticationGuard>
          <Auth0TokenProvider>
            <FullScreenContainer>
              <Navbar />
              <Outlet />
            </FullScreenContainer>
            <Toaster />
          </Auth0TokenProvider>
        </AuthenticationGuard>
      </Auth0ProviderWithHistory>
    </AppbarContextProvider>
  );
};

export default Root;
