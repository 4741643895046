import { type HTMLAttributes, forwardRef } from 'react';

import { Link } from '@pxui/components/ui/link';
import { cn } from '@pxui/lib/utils';

interface DetailsLinkProps extends HTMLAttributes<HTMLDivElement> {
  asButton?: boolean;
  button?: React.ReactElement;
  label?: string;
  linkLabel?: string;
  linkTo?: string;
}

const DetailsLink = forwardRef<HTMLDivElement, DetailsLinkProps>(
  (
    { className, label, linkLabel, linkTo, asButton, button, ...props },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn(
        'flex w-full py-[5px] px-4 justify-between items-start',
        className,
      )}
      {...props}
    >
      <span className="label-1 text-secondary">{label}</span>
      {asButton ? button : <Link href={linkTo}>{linkLabel}</Link>}
    </div>
  ),
);
DetailsLink.displayName = 'DetailsLink';

export { DetailsLink, type DetailsLinkProps };
