import { forwardRef, type HTMLAttributes } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses =
  'group rounded-xl inline-flex flex-col justify-start items-start min-w-[280px] w-[280px] overflow-hidden elevation-04';
const spacingClasses = 'gap-6';
const colorClasses = 'surface-01 text-primary';

export const DetailsCard = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(layoutClasses, spacingClasses, colorClasses, className)}
    {...props}
  />
));

DetailsCard.displayName = 'DetailsCard';

export default DetailsCard;
