import { type HTMLAttributes, forwardRef } from 'react';

import { cn } from '@pxui/lib/utils';

const layoutClasses = 'border-b';
const colorClasses = 'border-00-subtle';
const interactionClasses = 'hover:state-hover';

export const TableRow = forwardRef<
  HTMLTableRowElement,
  HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(layoutClasses, colorClasses, interactionClasses, className)}
    {...props}
  />
));

TableRow.displayName = 'TableRow';

export default TableRow;
