import { RouteObject } from 'react-router-dom';

import { lazyLoadHelper } from '@utils/lazyLoadHelper';

export const routes: RouteObject[] = [
  {
    index: true,
    lazy: lazyLoadHelper(() => import('./LauncherPage/LauncherPage')),
  },
  {
    lazy: lazyLoadHelper(() => import('./EmbeddedAppPage/EmbeddedAppPage')),
    path: '/app/:id',
  },
  // TODO: add 404 page
];

export default routes;
