import { type HTMLAttributes, forwardRef } from 'react';

import {
  NavigationMenu,
  NavigationMenuList,
} from '@pxui/components/ui/appBarNavigation';

export const AppBarNavigation = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ children }, ref) => (
  <NavigationMenu ref={ref}>
    <NavigationMenuList>{children}</NavigationMenuList>
  </NavigationMenu>
));

AppBarNavigation.displayName = 'AppBarNavigation';

export default AppBarNavigation;
