import type { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  NavigationMenuItem,
  navigationMenuTriggerStyle,
} from '@pxui/components/ui/appBarNavigation';
import { cn } from '@pxui/lib/utils';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';

const NavigationMenuLink = NavigationMenuPrimitive.Link;

interface AppBarLinkProps {
  disabled?: boolean;
  linkText: string;
  linkTo: string;
}

const AppBarLink: FC<AppBarLinkProps> = ({ linkText, linkTo, disabled }) => {
  const { pathname } = useLocation();

  const isActive = linkTo === pathname;

  return (
    <NavigationMenuItem>
      <NavigationMenuLink
        active={isActive}
        asChild
        className={cn(navigationMenuTriggerStyle(), {
          'after:absolute after:bg-blue-600 after:block after:bottom-[3px] after:w-3 after:h-[3px] after:rounded-[1px] after:state-focus text-primary':
            isActive,
          'text-disabled': disabled,
        })}
      >
        {disabled ? (
          <span>{linkText}</span>
        ) : (
          <Link to={linkTo}>{linkText}</Link>
        )}
      </NavigationMenuLink>
    </NavigationMenuItem>
  );
};

AppBarLink.displayName = 'AppBarLink';

export { NavigationMenuLink, AppBarLink, type AppBarLinkProps };
