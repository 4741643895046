const domain = `${process.env.PLATFORM_NAMESPACE}.cloud.physicsx.ai`;

export const JUPYTERHUB_URL = `https://jupyterhub.${domain}`;
export const JUPYTERHUB_SHARED_URL = `https://jupyterhub.${domain}/user/${process.env.PLATFORM_NAMESPACE}-collab`;
export const MLFLOW_URL = `https://mlflow.${domain}`;
export const ARGO_URL = `https://argo-workflows.${domain}`;
export const DOCS_URL = `https://docs.${domain}`;
export const REGISTER_PIPELINES_DOCS_URL = `https://docs.${domain}/cli/user_guide/flow_pipelines.html`;
// VSCode url comes in 2 parts
export const VSCODE_PREFIX_URL = `${JUPYTERHUB_URL}/user`; // user email goes here, that needs to be populated inside a react component
export const VSCODE_SUFFIX_URL = `codeserver/?folder=/home/jovyan`;

export const PRE_UNZIPPING_UPLOAD_PROGRESS_LIMIT = 95;
export const ERROR_TOAST_DELAY_MS = 1000;

export const PAGE_SIZE = 100;

// General length constraints
export const MIN_LENGTH = 3; // Minimum length for various fields
export const MAX_NAME_LENGTH = 250; // Maximum length for name-related fields
export const MAX_DESCRIPTION_LENGTH = 500; // Maximum length for description fields
export const MAX_COMMIT_SHA_LENGTH = 50; // Maximum length for commit SHA

// Base64 encoding specifics
export const BASE64_CHAR_LENGTH = 4; // Each 3 bytes of data is represented by 4 Base64 characters
export const BYTES_PER_KILOBYTE = 1024;

// Icon specifics
export const MAX_ICON_SIZE_KB = 300; // Maximum icon size in kilobytes
export const MAX_ICON_DATA_LENGTH =
  BASE64_CHAR_LENGTH *
  Math.ceil((BYTES_PER_KILOBYTE * MAX_ICON_SIZE_KB) / MIN_LENGTH);
