import PolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkITKPolyDataReader from '@kitware/vtk.js/IO/Misc/ITKPolyDataReader';
import { ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import axios from 'axios';

export interface VtkField {
  id: string;
  label: string;
  range: [number, number];
  scalarMode: ScalarMode;
}

export function getFieldsFromVtkPolydata(polydata: PolyData) {
  const fields: VtkField[] = [];

  if (!polydata) {
    return fields;
  }

  polydata
    .getPointData()
    .getArrays()
    .forEach((a: any) => {
      fields.push({
        id: a.getName(),
        label: `(pointData) ${a.getName()}`,
        range: a.getRange(),
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA,
      });
    });

  polydata
    .getCellData()
    .getArrays()
    .forEach((a) => {
      fields.push({
        id: a.getName(),
        label: `(cellData) ${a.getName()}`,
        range: a.getRange(),
        scalarMode: ScalarMode.USE_CELL_FIELD_DATA,
      });
    });

  polydata
    .getFieldData()
    .getArrays()
    .forEach((a) => {
      fields.push({
        id: a.getName(),
        label: `(fieldData) ${a.getName()}`,
        range: a.getRange(),
        scalarMode: ScalarMode.USE_FIELD_DATA,
      });
    });

  return fields;
}

export async function parseArrayBufferToPolydata(
  arrayBuffer: ArrayBuffer,
  fileName: string,
): Promise<PolyData> {
  //  should do a similar thing to Glance's io/ReaderFactory.js:readRawData
  //  https://github.com/Kitware/glance/blob/master/src/io/ReaderFactory.js#L86
  const itkReader = vtkITKPolyDataReader.newInstance();

  itkReader.setFileName(fileName);

  //  it doesn't return anything actually
  await itkReader.parseAsArrayBuffer(arrayBuffer);

  return itkReader.getOutputData(0);
}

export async function fetchArrayBufferFromS3(url: string) {
  //  @TODO - should reuse services/downloadFromS3
  const resp = await axios.get(url, {
    responseType: 'arraybuffer',
  });
  return resp.data;
}

export function parseFileNameFromPresignedUrl(url: string): string | null {
  try {
    const parsedUrl = new URL(url);
    const { pathname } = parsedUrl;
    const fileNameMatch = pathname.match(/\/([^/]+)$/);

    if (fileNameMatch) {
      const fileName = fileNameMatch[1];
      return fileName;
    }

    return null;
  } catch (error) {
    console.error('Invalid presigned URL', error);
    return null;
  }
}
