import type { FC, ReactNode } from 'react';

import {
  NavigationMenuContent,
  NavigationMenuTrigger,
} from '@pxui/components/ui/appBarNavigation';
import cn from '@pxui/lib/utils';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';

const NavigationMenuItem = NavigationMenuPrimitive.Item;

interface AppBarDropdownProps {
  children: ReactNode;
  linkText: string;
}

const layoutClasses =
  'group relative inline-flex items-center justify-center rounded';

const spacingClasses = 'px-3 py-[7px]';

const colorsClasses =
  'transition-colors duration-150 ease-out hover:state-hover';

const AppBarDropdown: FC<AppBarDropdownProps> = ({ linkText, children }) => (
  <NavigationMenuItem>
    <NavigationMenuTrigger
      className={cn(
        'cursor-pointer label-1 text-secondary',
        layoutClasses,
        spacingClasses,
        colorsClasses,
      )}
    >
      {linkText}
    </NavigationMenuTrigger>
    <NavigationMenuContent className="elevation-04 bg-transparent rounded flex-1 w-max px-3 py-1">
      {children}
    </NavigationMenuContent>
  </NavigationMenuItem>
);

AppBarDropdown.displayName = 'AppBarDropdown';

export { AppBarDropdown, NavigationMenuItem, type AppBarDropdownProps };
